@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&family=Rajdhani:wght@300&display=swap');

:root{
  --primary: #efb6b2;
  --secondary: #4e4e4e;
  --error: #ff4a4a;
}

/* base styles & title */
body{
  font-family: 'Cormorant Garamond', serif;
  color: var(--secondary);
  background-color: black;
}
.App{
  max-width: 960px;
  margin: 0 auto;
}
.title h1{
  color: var(--primary);
  font-size: 1.2rem;
  letter-spacing: 2px;
  font-weight: normal;
}
.title h2, .title p{
  text-align: center;
}
.title h2{
  margin-top: 60px;
  font-size: 2.4rem;
  color: white;
  padding: 0;
}

.title h5{
  margin-top: -30px;
  text-align: center;
  color: white;
  font-size: 1.2rem;
}

.title p{
  color: white;
  text-align: center;
  margin-left: 0.2rem;
  font-family: 'Rajdhani', sans-serif;
  font-size: 0.8rem;
}

/* upload form styles */


/* progress bar styles */
.progress-bar{
  height: 5px;
  background: var(--primary);
  margin-top: 20px;
}

/* image grid styles */
.img-grid{
  margin: 10px auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
}
/*
.img-wrap{
  width: 100%;
  height: 900px;

  overflow: hidden;
  margin: 0;
  position: relative;
}
.img-wrap img{
  position:absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
}
*/


.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

/* modal styles */

.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}
.backdrop img{
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
}